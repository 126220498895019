import { Injectable } from '@angular/core';
import {
  Channel,
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Router } from '@angular/router';
import { FCM } from "@capacitor-community/fcm";

@Injectable({
  providedIn: 'root'
})
export class PushServiceService {

  meinToken: string = undefined;

  constructor(private router: Router) { }

  async initPush() {
    await Device.getInfo().then((res) => {
      const meinePltf = res.platform;
      if (meinePltf !== 'web' || 'ios') {
        this.registerPush();
      } if (meinePltf === 'ios') {
        this.registerPushiOS();
      } else {
        console.log('Webanwendung - PushBenachrichtigung nicht aktiv')
      }
    })
  }

  //Android-Anmeldung
  private async registerPush() {

    await PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.log('Keine Push Notification möglich')
      }
    });

    // On success, we should be able to receive notifications
    await PushNotifications.addListener('registration',
      (token: Token) => {
        this.meinToken = token.value;
        localStorage.setItem('merkeToken', this.meinToken)
        console.log('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    await PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.router.navigateByUrl('/news')
      }
    )
  }

  //iOS-Anmeldung
  private async registerPushiOS() {

    await PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.log('Keine Push Notification möglich')
      }
    });

    // On success, we should be able to receive notifications
    this.meinToken = (await FCM.getToken()).token
    localStorage.setItem('merkeToken', this.meinToken)
    console.log('Push registration success, token: ' + this.meinToken);


    // Some issue with our setup and push will not work
    await PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.router.navigateByUrl('/news')
        let channel: Channel;
        PushNotifications.removeAllDeliveredNotifications();
        PushNotifications.removeAllListeners();
        PushNotifications.deleteChannel(channel)
      }
    )
  }
}
