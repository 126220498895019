export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyA2sxVzHIhrpqBlJjJoKX7wLVwldyLhPh0",
    authDomain: "azubicon.firebaseapp.com",
    projectId: "azubicon",
    storageBucket: "azubicon.appspot.com",
    messagingSenderId: "390254602110",
    appId: "1:390254602110:web:201d44653d9f339d54352f",
    measurementId: "G-NW4PBHGNEX"
  }

};
