import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'baustellen',
    loadChildren: () => import('./baustellen/baustellen.module').then( m => m.BaustellenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'download',
    loadChildren: () => import('./download/download.module').then( m => m.DownloadPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./newsordner/news/news.module').then( m => m.NewsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./kontakt/kontakt.module').then( m => m.KontaktPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'newsdetails/:id',
    loadChildren: () => import('./newsordner/newsdetails/newsdetails.module').then( m => m.NewsdetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pwreset',
    loadChildren: () => import('./pwreset/pwreset.module').then( m => m.PwresetPageModule)
  },
  {
    path: 'loginfehler',
    loadChildren: () => import('./loginfehler/loginfehler.module').then( m => m.LoginfehlerPageModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then( m => m.ImpressumPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'newserstellen',
    loadChildren: () => import('./newsordner/newserstellen/newserstellen.module').then( m => m.NewserstellenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gruppenliste',
    loadChildren: () => import('./gruppenordner/gruppenliste/gruppenliste.module').then( m => m.GruppenlistePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gruppenchat/:id',
    loadChildren: () => import('./gruppenordner/gruppenchat/gruppenchat.module').then( m => m.GruppenchatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gruppenstart',
    loadChildren: () => import('./gruppenordner/gruppenstart/gruppenstart.module').then( m => m.GruppenstartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gruppendetails/:id',
    loadChildren: () => import('./gruppenordner/gruppendetails/gruppendetails.module').then( m => m.GruppendetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profil',
    loadChildren: () => import('./profil/profil/profil.module').then( m => m.ProfilPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profilerstellen',
    loadChildren: () => import('./profil/profilerstellen/profilerstellen.module').then( m => m.ProfilerstellenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nutzermodal',
    loadChildren: () => import('./gruppenordner/nutzermodal/nutzermodal.module').then( m => m.NutzermodalPageModule)
  },
  {
    path: 'upload',
    loadChildren: () => import('./download/upload/upload.module').then( m => m.UploadPageModule),
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
